

/*
    Our impact page
*/

.scholar-stats {
    margin-top: 160px;
    margin-bottom: 160px;

    .heading2 {
        margin-bottom: 36px;
    }

    ul {
        padding-left: 0;

        li {
            color: $charcoal-grey;
            letter-spacing: normal;
            line-height: 1.5;
            list-style: none;
            margin-top: 20px;
        }
    }
}



.life-changing-stats {

    @include media-breakpoint-down(lg) {
        padding-top: 69px;
        padding-bottom: 69px;
    }

    .shortline-blue {
        border-top: 1px solid $twilight-blue;
        width: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .stat {
        font-family: Europa, sans-serif;
        margin-top: 120px;
        text-align: center;

        @include media-breakpoint-down(lg) {
            margin-top: 50px;
        }

        &:not(:last-of-type) {
             border-right: 1px solid $twilight-blue;
        }

        @include media-breakpoint-down(md) {
            &:not(:last-of-type) {
                border-right: none;
            }
        }


        .number {
            color: $twilight-blue;
            font-size: 86px;
            font-size: 8.6rem;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 0.84;
            margin-bottom: 20px;
        }

        .job-title {
            color: $twilight-blue;
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.58;
            letter-spacing: 1.9px;
            text-align: center;
            text-transform: uppercase;

             @include media-breakpoint-down(lg) {
                font-size: 10px;
                font-size: 1.0rem;
            }
        }
    }
}


