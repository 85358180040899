/*
    Colours
*/
$black: #000;
$charcoal-grey: #3a434f;
$dark-grey: #212934;
$light-mint: #c4e5d9;
$dark-mint: #49c4a1;
$light-grey: #f2f5fa;
$pale-grey: #f5f5f6;
$gray: #567b95;
$salmon: #ee7070;
$stormy-blue: #4e87a0;
$twilight-blue: #074a76;
$white: #fff;
$link-blue: #567b95;
$gold: #edb625;

/*
    General
*/
html {
  scroll-behavior: smooth;
}
html,
body {
  background-color: $white;
  font-size: 62.5%;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.no-scroll {
    overflow: hidden;
  }
}
#__next[aria-hidden="true"] {
  display: block; //undoes display: none when entire page has aria-hidden=true
}

#new-tab {
  font-size: 1.5em;
}

.heading1 {
  color: $twilight-blue;
  font-family: "bressay-display", serif;
  font-size: 72px;
  font-size: 7.2rem;
  line-height: 1.25;
  letter-spacing: -2px;
  // text-transform: capitalize;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-down(md) {
    font-size: 48px;
    font-size: 4.8rem;
  }
}

.heading2 {
  color: $twilight-blue;
  font-family: "bressay-display", serif;
  font-size: 54px;
  font-size: 5.4rem;
  line-height: 1.11;
  letter-spacing: -1px;

  @include media-breakpoint-down(lg) {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.17;
  }
}

.faq-heading {
  color: $twilight-blue;
  font-family: "bressay-display", serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.11;
  letter-spacing: -1px;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.17;
  }
}

.heading3,
p,
.p-body {
  color: $charcoal-grey;
  font-family: Europa, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  overflow-wrap: break-word;

  @include media-breakpoint-down(lg) {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.heading3 {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $twilight-blue;
}

.heading4 {
  color: $charcoal-grey;
  font-family: Europa, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1.6px;
}

.disclaimer {
  color: $charcoal-grey;
  font-family: Europa, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.29;

  @include media-breakpoint-down(md) {
    margin-top: 0px;
  }

  p {
    a {
      color: $charcoal-grey;
    }
  }
}

.mt-200 {
  margin-top: 200px;
  @include media-breakpoint-down(md) {
    margin-top: 26px;
  }
}

ul#main-menu,
ul#menu-footer-nav-1,
ul#menu-footer-nav-2,
#secondary-menu {
  li,
  li a {
    list-style: none;
    text-transform: uppercase;
    font-family: Europa, sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    letter-spacing: normal;
  }
}

ul,
li {
  font-size: 18px;
  font-size: 1.8rem;
}
// nav ul#main-menu li a {
//     @include media-breakpoint-down(lg) {
//         padding: 23px 17px;
//         font-size: 1.3rem;
//     }
// }

ul#menu-footer-nav-1,
ul#menu-footer-nav-2,
.footer-top__links {
  padding: 0;
}

.link {
  border: 1px solid $twilight-blue;
  color: $twilight-blue;
  display: inline-block;
  font-family: Europa;
  font-size: 13px;
  font-weight: bold;
  line-height: 2.25;
  letter-spacing: 1.9px;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 7.5px 25px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: border-color 0.5s linear 0.1s;
  &:hover,
  &:focus,
  &:active {
    color: $twilight-blue;
    cursor: pointer;
    border-color: transparent;
    text-decoration: none;
    transition: border-color linear;
    &:after {
      border-width: 1px;
      animation: aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
      animation-delay: 0s, 0.25s, 0.5s;
    }
    &:before {
      border-width: 1px;
      animation: nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
      animation-delay: 0s, 0.5s, 0.75s, 1s;
    }
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 0%;
    z-index: 1;
    border-top: 1px solid $twilight-blue;
    border-right: 1px solid $twilight-blue;
    border-width: 0px;
  }
  &:before {
    content: " ";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 0%;
    height: 0%;
    z-index: 1;
    border-bottom: 1px solid $twilight-blue;
    border-left: 1px solid $twilight-blue;
    border-width: 0px;
  }
}

/* A weird set of keyframes that can do anything */
@keyframes aW_sH {
  from {
    height: 100%;
    width: 0%;
  }
  to {
    height: 100%;
    width: 100%;
  }
}
@keyframes sW_aH {
  from {
    width: 100%;
    height: 0%;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@keyframes sW_sH {
  from {
    width: 100%;
    height: 100%;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@keyframes nW_nH {
  from,
  to {
    border-width: 0px;
  }
}
@keyframes nW_aH {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
@keyframes nW_sH {
  from,
  to {
    height: 100%;
  }
}
@keyframes sW_nH {
  from,
  to {
    width: 100%;
  }
}
@keyframes aW_nH {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1400px !important;
  }
}

@include media-breakpoint-down(md) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.screen-reader-text,
.sr-only {
  font-size: 18px;
  font-size: 1.8rem;
}

.accordions {
  margin-top: 80px;

  @include media-breakpoint-down(lg) {
    margin-top: 50px;
  }

  .accordion {
    border-bottom: 1px solid $stormy-blue;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    transition: 0.25s;

    &:first-of-type {
      border-top: 1px solid $stormy-blue;
      margin-top: 50px;

      @include media-breakpoint-down(lg) {
        margin-top: 23px;
      }
    }

    h2 {
      margin-bottom: 0px;
    }

    .accordion-control {
      cursor: pointer;
      padding-right: 30px;
      position: relative;
    }

    .content {
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 0.25s linear;

      a {
        letter-spacing: normal;
        text-transform: none;
      }
    }

    .read-more-icon {
      position: absolute;
      top: 12px;
      right: 18px;

      span {
        background: $twilight-blue;
        border-radius: 9px;
        display: block;
        height: 2px;
        opacity: 1;
        position: absolute;
        left: 0;
        width: 18px;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          transform: rotate(90deg);
        }

        &.open span:nth-child(1) {
          transform: rotate(0);
        }
      }
    }

    h2[aria-expanded="true"] {
      span:nth-child(1) {
        transform: rotate(135deg);
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }
    }

    div.content[aria-hidden="false"] {
      margin-top: 30px;
    }
  }
}

[aria-hidden="true"] {
  display: none;
}

.no-vacancies {
  margin-top: 60px;
}

/*
    Navbar
*/

// Body styles for nav bar scroll functionality

body {
  padding-top: 65px;
}

@media (min-width: 1130px) {
  .navbar-expand-custom {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-custom .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

.navbar {
  padding: 0 0 0 30px;
  background-color: $white;
  min-width: 100%;
  position: fixed;
  top: 0px;
  transition: top 0.25s ease-in-out;
  z-index: 10;

  &.hidden {
    top: -80px;
  }

  a {
    text-decoration: none;
    border-bottom: unset;

    &:hover {
      transition: unset;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0 0 20px;
  }
}

.nav-up {
  top: -75px;
}

.nav-mobile {
  position: relative;
  top: 0;
}

.navbar-dark .navbar-nav a.nav-link {
  color: $twilight-blue;
  font-weight: bold;
  letter-spacing: 0.4px;
  padding: 23px 28px;
  transition: color 0.2s ease-in-out;

  &:after {
    transition: color 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    color: $gray;
    padding: 23px 28px;
    transition: color 0.2s ease-in-out;
    &:after {
      color: $gray;
      transition: color 0.2s ease-in-out;
    }
  }
}

.dropdown-menu {
  background-color: $twilight-blue;
  border: none;
  border-radius: 0;
  display: none;
  margin-top: 3px;
  min-width: 100%;
  padding: 15px 0;
  transition: visibility 0.25s ease-in-out;
  visibility: hidden;

  a {
    color: $white;
    text-decoration: none;
    transition: unset;
    padding: 10px 20px;
    border-bottom: unset;

    &:hover,
    &:focus {
      text-decoration: underline;
      border-bottom: unset;
    }
  }

  &.show {
    transition: visibility 0.25s ease-in-out;
    visibility: visible;
  }
}

.dropdown-item {
  padding: 10px 20px;

  &:focus,
  &:hover {
    background-color: $twilight-blue;
    color: $white;
    border-bottom: unset;
  }
}

.navbar-dark .navbar-nav .show > .nav-link {
  color: $twilight-blue;
}

.dropdown-toggle::after {
  border: none;
  color: $stormy-blue;
  content: "\f107";
  font-family: "Font Awesome\ 5 Free";
  position: relative;
  transform: none;
  transition: transform 0.1s ease-in-out;
  top: 5px;
  right: -5px;
}

.navbar-dark .navbar-nav .show {
  .dropdown-toggle::after {
    transform: rotate(180deg) translateY(2px);
    transition: transform 0.1s ease-in-out;
  }
}

.navbar-dark .navbar-nav .active > .nav-link {
  color: $twilight-blue;
}

ul#main-menu > li:last-child {
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: $black;
    background-color: $gold;
    height: auto;
    width: auto;
    padding: 2rem 3rem !important;
    margin: 1rem;
    font-size: 15px;
    border: 2px solid $gold;
  }
}

ul#main-menu > li:last-child:hover {


    a { 
        background-color: white !important;
        border: 2px solid $twilight-blue;
        color: $twilight-blue;
    }
}

#secondary-menu {
  li:last-child {
    a {
      color: $white;
      background-color: $twilight-blue;
      border: 3px solid $twilight-blue;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      width: 138px;

      &:hover {
        color: $twilight-blue;
        background-color: $white;
        border: 3px solid $twilight-blue;
      }
    }
  }
  @media (max-width: 1130px) {
    display: none;
  }

  i {
    margin-left: 5px;
    display: inline;
  }
}

.hamburger {
  border: none;
  cursor: pointer;

  span.hamburger-box {
    span.hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
      background-color: $twilight-blue !important;

      height: 2px;
      transition: all 0.25s ease-in;
    }
  }
}

@media (max-width: 1130px) {
  .navbar-brand img {
    height: 30px;
  }
}

/*
    Footer
*/
footer {
  ul.social {
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      a {
        color: $white;
        margin-right: 1rem;
        span {
          font-size: 1.5rem;
          transition: opacity 0.15s ease-in-out;
        }
        &:hover {
          cursor: pointer;
          span {
            opacity: 0.6;
            transition: opacity 0.15s ease-in-out;
          }
        }
      }
      &:before {
        content: "";
      }
    }
    .fa-facebook-f:before {
      content: "\f39e";
    }
  }

  .footer-left {
    padding: 0;
  }

  .footer-logo {
    max-height: 60px;
    height: 60px; //setting by height since on seal extends further out width wise
    margin-left: -5px;
    margin-right: 15px;
    @media only screen and (max-width: 480px) {
      max-height: 50px;
      margin-bottom: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .hidden-break {
    display: none;
    @media only screen and (max-width: 480px) {
      display: block;
    }
  }

  .footer-top {
    background-color: $dark-grey;
    color: $white;
    padding-top: 80px;

    @include media-breakpoint-down(md) {
      padding-top: 40px;
    }

    a {
      color: $white;
    }

    p {
      color: $pale-grey;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        font-size: 1.4rem;
      }
    }

    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          color: $white;
          font-size: 16px;
          font-size: 1.6rem;
          font-weight: bold;
          letter-spacing: 1px;
          position: relative;
          border-bottom: unset;

          &:hover {
            border-bottom: unset;
            cursor: pointer;
          }
        }
      }

      &.menu {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 125px;
        @include media-breakpoint-down(sm) {
          max-height: 100%;
        }
      }

      &.footer-top__social {
        display: none;
        margin-bottom: -20px;
        margin-top: 20px;

        @include media-breakpoint-down(lg) {
          display: block;
        }
      }
    }

    hr {
      border-color: $light-grey;
      margin: 40px 0;
      opacity: 0.2;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .footer__border {
      border-bottom: 1px solid rgba($pale-grey, 0.2);
      padding-bottom: 80px;
      @include media-breakpoint-down(md) {
        padding-bottom: 40px;
      }
    }
  }

  .footer-bottom {
    background-color: $dark-grey;
    color: $white;
    padding: 40px 15px;
    width: 100%;

    .row {
      align-items: baseline;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .footer-bottom--flex {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 0;

        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }
    }

    .footer-bottom__links {
      padding: 0;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      li {
        display: inline-block;
        position: relative;

        a {
          font-family: Europa, sans-serif;
          font-size: 12px;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 2.25;
          letter-spacing: 1px;
          color: $pale-grey;

          border-bottom: unset;

          &:hover {
            border-bottom: unset;
          }
        }
      }
    }

    .footer-divider {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2.25;
      letter-spacing: 1px;
      margin: 0 10px;
    }

    ul {
      &.footer-bottom__social {
        display: block;
        margin-left: 10px;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    .footer-bottom__copyright {
      font-family: Europa, sans-serif;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 2.25;
      letter-spacing: 1px;
      color: $pale-grey;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}

// About Us Fix
.page-template-about-us {
  .mt-200 {
    margin-top: 0;
  }
}

// Contact Form
div.wpcf7-response-output {
  font-size: 20px !important;
  font-size: 2rem !important;
}

.wpcf7 .wpcf7-validation-errors {
  height: auto !important;
}

// Global Content Box Sizing:

.image-callout__image {
  min-height: 90vh;
  @include media-breakpoint-down(lg) {
    min-height: 80vh;
  }
  @include media-breakpoint-down(md) {
    min-height: 50vh;
  }
}

.image-callout__image--banner {
  min-height: 100vh;
  @include media-breakpoint-down(md) {
    min-height: auto;
  }
}

.middle__callout-text {
  min-height: 60vh;
  @include media-breakpoint-down(lg) {
    min-height: 50vh;
  }
  @include media-breakpoint-down(md) {
    min-height: auto;
  }
}

// Scroll Animation
.animate {
  opacity: 0;
}

.intro__halfbleed .intro__link {
  margin-top: 48px;
}

.skip {
  display: block;
  min-width: 100%;
}

#content {
  position: relative;
  overflow: hidden;
}

.single-page {
  min-height: 35vh;
}

.link--absolute {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
}

.link--inactive {
  border: 1px solid $twilight-blue;
  color: $twilight-blue;
  display: inline-block;
  font-family: Europa;
  font-size: 13px;
  font-weight: bold;
  line-height: 2.25;
  letter-spacing: 1.9px;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 7.5px 25px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.5;
}

.gallery-layout .intro__fullbleed .intro__heading {
  margin-bottom: 0;
}

.donate-video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $twilight-blue;
  width: 100vw;
  margin: 0;
  padding-top: 80px;
}