

/*
    Leadership page
*/

.intro__heading {
    margin-bottom: 20px;
}

.page-template-leadership {
    .intro__halfbleed {
        margin-bottom: 0px;
    }
}
.founders {

    @include media-breakpoint-down(md) {
        margin-top: 40px;
    }

    .founders__text {

        max-width: 800px;
        padding-bottom: 50px;

        @include media-breakpoint-down(md) {
            padding-right: 20px;
            padding-left: 20px;
            text-align: center;
            padding-bottom: 27px;
            margin-top: unset;
        }

        .heading2 {
            margin-bottom: 20px;
        }
    }

    .founders__staff {
        padding-top: 3%;
        padding-left: 3%;
        padding-right: 3%;

        @include media-breakpoint-down(sm) {
            padding-left: 0px;
            padding-right: 0px;
        }

        a {
            font-size: 12px;
            font-size: 1.2rem;
            letter-spacing: 1.9;
            line-height: 2.25;
        }

        .staff__image {
            height: auto;
            width: 100%;
            background-size: cover;
            background-position: top;
            min-height:272px;


            @include media-breakpoint-down(lg) {
                background-size: cover;
                background-repeat: no-repeat;
                height: 234px;
                background-position: top;
                min-height: unset;
            }

            @include media-breakpoint-down(md) {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;
                min-height: 44vw;
            }

            @include media-breakpoint-down(sm) {
                background-size: cover;
                background-repeat: no-repeat;
                height: 60vw;
                background-position: top;
                min-height: 234px;
            }

        }

        .staff__text {
            background-color: white;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: unset;
                padding: 25px 27px 30px 27px;
            }
        }

        .staff__name {
            color: $twilight-blue;
            font-family: Europa, serif;
            font-size: 30px;
            font-size: 3.0rem;
            font-weight: normal;
            line-height: 1.25;
            letter-spacing: -1px;
            padding-top: 5px;
            text-transform: none;
            height:35px;
            background-color: white;
            width: max-content;


            @include media-breakpoint-down(lg) {
                position: relative;
                font-size: 22px;
                font-size: 2.2rem;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.23;
                letter-spacing: normal;
                padding-top: 12px;

            }

            @include media-breakpoint-down(md) {
               padding-top: 5px; 
            }


        }

        .staff__title {
            color: $charcoal-grey;
            font-family: Europa, sans-serif;
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: normal;
            letter-spacing: normal;
            line-height: 1.29;
            text-transform: none;
            padding-bottom: 25px;
            padding-top: 12px;

            @include media-breakpoint-down(md) {
                padding-bottom: 11px;
            }
        }
    }
}


.board-advisers {

     @include media-breakpoint-down(md) {
                padding-top:50px;
                text-align: center;
            }
    .director, .adviser {
        margin-top: 45px;
        border-bottom: 1px solid $stormy-blue;
        padding-bottom: 20px;

        .director__name, .adviser__name {
            color: $charcoal-grey;
        }

        .director__title, .adviser__title {
            font-weight: normal;
            line-height: 1.29;
            letter-spacing: normal;
        }

         @include media-breakpoint-down(md) {
            margin-top: 30px;
            margin-left: 7%;
            margin-right: 7%;
        }
    }

    .heading2 {
        margin-bottom: 33px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0px;
        }
    }
}



.board-advisers {
    margin-top: 100px;
    margin-bottom:200px;



    @include media-breakpoint-down(md) {
        margin-top: 15px;
        margin-bottom: 75px;
    }

}

.leadership-bio-intro {
    // background: linear-gradient(to bottom, $light-grey 90%, $white 0);
    // margin-bottom: 80px;
    // padding-top: 80px;

    // @include media-breakpoint-down(md) {
    //     background: none;
    //     margin-bottom: 40px;
    //     padding-top: 0;
    // }

    padding: 0;

    .leadership-bio-intro__heading {
        padding-top: 120px;

        @include media-breakpoint-down(md) {
            padding-top: 60px;
        }

        .heading1 {
            color: $white;
            font-size: 54px;
            font-size: 5.4rem;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                font-size: 32px;
                font-size: 3.2rem;
                margin-bottom: 0;
            }
        }

        .heading3 {
            color: $white;
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: normal;
            @include media-breakpoint-down(md) {
                font-size: 14px;
                font-size: 1.4rem;
            }
        }
    }

    .leadership-bio-intro__image {
        position: relative;
        overflow: hidden;
        min-height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        -webkit-display: flex;
        -webkit-justify-content: center;

        @include media-breakpoint-down(md) {
            min-height: 60vh;
        }

        @include media-breakpoint-down(sm) {
            min-height: 45vh;
        }

        img {
            bottom: 0;
            left: 0;
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
            object-position: right;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.leadership-bio-text {
    margin-bottom: 140px;
    margin-top: 140px;

    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
        margin-top: 70px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 35px;
        margin-top: 35px;
    }
}

.page-template-leadership {
    .image-callout {
        &.mt-200 {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
        .image-callout__image--banner {
            min-height: 96vh;
            @include media-breakpoint-down(md) {
                min-height: auto;
            }
        }
    }
}

.board-directors {

    .director_modal_bio {
        display: none;
    }

    .heading2 {
        margin-bottom: 80px;
        @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
    }

    .director__name {
            color: $twilight-blue;
            font-family: Europa, serif;
            font-size: 30px;
            font-size: 3.0rem;
            font-weight: normal;
            line-height: 1.25;
            letter-spacing: -1px;
            padding-top: 5px;
            text-transform: none;
            background-color: white;


            @include media-breakpoint-down(lg) {
                position: relative;
                font-size: 22px;
                font-size: 2.2rem;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.23;
                letter-spacing: normal;
                padding-top: 12px;

            }

            @include media-breakpoint-down(md) {
               padding-top: 5px; 
            }


        }

        .director__title {
            color: $charcoal-grey;
            font-family: Europa, sans-serif;
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: normal;
            letter-spacing: normal;
            line-height: 1.29;
            text-transform: none;
            padding-bottom: 25px;
            padding-top: 5px;

            @include media-breakpoint-down(md) {
                padding-bottom: 11px;
            }
        }
    .director {
        max-width: 325px;

        @include media-breakpoint-down(md) {
                margin: 0 auto;
            }
    }
    .director-bio-link {
        position: relative;
        padding: 0px;
        margin: 0;
        background: transparent;
        border: none;

        width: 100%;

        &:hover {
            .director-viewbio {
                opacity: 1;
                transition: opacity .5s ease-in-out .1s;
            }
        }

        .director-viewbio {
        max-width: 325px;
        width: 100%;
        height: 272px;
        background-color: rgba(7, 74, 118, 0.85);
        position: absolute;
        top:0;
        opacity: 0;
        transition: opacity 0.5s ease-in-out 0.1s;

        .link {
              border: 1px solid #ffffff;
              color: #ffffff;
              display: inline-block;
              font-family: Europa;
              font-size: 13px;
              font-weight: bold;
              line-height: 2.25;
              letter-spacing: 1.9px;
              margin: 0.5rem 0.5rem 0.5rem 0;
              padding: 7.5px 25px;
              position: relative;
              text-decoration: none;
              text-transform: uppercase;
              transition: border-color 0.5s linear 0.1s;
              &:hover, &:focus, &:active {
                color: #ffffff;
                cursor: pointer;
                border-color: transparent;
                text-decoration: none;
                transition: border-color linear;
                &:after {
                    border-width:1px;
                    animation:aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
                    animation-delay:0s, 0.25s, 0.5s;
                }
                &:before {
                    border-width:1px;
                    animation:nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
                    animation-delay:0s, 0.5s, 0.75s, 1s;
                }
              }
              &:after {
                content: ' ';
                position:absolute;
                top:0px;
                left:0px;
                width:0%;
                height:0%;
                z-index:1;
                border-top:1px solid #ffffff;
                border-right:1px solid #ffffff;
                border-width:0px;
              }
              &:before {
                content: ' ';
                position:absolute;
                bottom:0px;
                right:0px;
                width:0%;
                height:0%;
                z-index:1;
                border-bottom:1px solid #ffffff;
                border-left:1px solid #ffffff;
                border-width:0px;
              }
               // @include media-breakpoint-down(lg) {
               //      font-size: 10px;
               //      font-size: 1.0rem;
               //      line-height: 2.7px;
               //      letter-spacing: 1.6px;
               //      padding: 10px 7.5px 7.5px;
               //  }
            }
    }
    }
    .director__image {
        background-size: cover;
        background-repeat: no-repeat;
        max-width: 325px;
        width: 100%;
        height: 272px;
        background-position: top;
    }

}

.ReactModal__Overlay {
    z-index: 11;
    background-color: rgba(0, 0, 0, .75) !important;
    display: flex;
    justify-content: center;
    align-content: center;
}
#biomodal {
    max-width: 100vw;
    width: 90%;
    height: 80%;
    top: 10%;
    position: relative;
    background: $twilight-blue;
    

    .biomodal-content {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        
        @include media-breakpoint-down(md) {
            padding: 10%;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            padding: 7%;
     
        }

    }

    h2 {
        font-family: Europa, sans-serif;
        font-size: 30px;
        font-size: 3rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 11px;
    }

    h3 {

        font-family: Europa, sans-serif;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #ffffff;
      max-width: 325px;
      margin-bottom: 59px;
    }

    p {
    font-family: Europa, sans-serif;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
    }
    a {
        color: white;
        text-decoration: underline;
    }

    .biomodal_close {
        padding:25px;
        position: absolute;
        top: 0;
        right:0;
        background: transparent;
        border: none;
    }

  }
