/*
    Donate page
*/
.donate-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 5rem;

    h1 {
        max-width: 800px;
    }
}
.donate-img-contain {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        max-width: 950px !important;
    }
}
.donate-button-container {
    display: flex;
    gap: 5px; 
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .donate-button-container {
        flex-direction: column;
    }
}

.donate-link {
    min-width: 188px;
    font-size: 24px;
    font-weight: bold;
    line-height: 2.25;
    letter-spacing: 1.9px;
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 7.5px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
}

.donate-link-dark {
    background-color: $twilight-blue;
    color: $white;
    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $twilight-blue;
    }
}