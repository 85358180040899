

/*
    CTA-banner
*/
.cta-banner {
    background-color: $twilight-blue;
    padding: 8rem 0;

    .heading1 {
        color: $white;
        letter-spacing: -1px;
        line-height: 1;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            font-size: 39px;
            font-size: 3.9rem;
        }
    }

    .cta-banner__link {
        margin-top: 40px;
        margin-bottom: 0;
        a {
            color: $white;
            border-color: $white;
            transition: border-color 0.5s linear 0.1s;
            &:after, &:before {
                border-color: $white;
            }
            &:hover, &:focus {
                border-color: transparent;
                transition: border-color linear;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 60px;
    }
}