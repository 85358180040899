

/*
    Careers page
*/

.careers {
	h1 {
		margin-bottom: 21px;

		 @include media-breakpoint-down(lg) {
        font-size: 32px;
        font-size: 3.2rem;
        line-height: 1.17;

    }
	}

	.accordions {
		margin-top: 10px;

		.accordion {
			padding-top: 28px;
			padding-bottom: 28px;
		}
	}
	.heading3 {
		text-transform: none;
	}

	.content {
		p {
			font-size: 15px;
			font-size: 1.5rem;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.47;
		}

    a.link {
        letter-spacing: 2.03px;
        text-transform: uppercase;
        margin-top: 15px;
    }
	}


	.internship-heading {
    color: $twilight-blue;
    font-family: "bressay-display", serif;
    font-size: 40px;
    font-size: 4rem;
    line-height: 1.11;
    letter-spacing: -1px;
    margin-top: 80px;

    @include media-breakpoint-down(lg) {
        font-size: 32px;
        font-size: 3.2rem;
        line-height: 1.17;

    }
	}
}