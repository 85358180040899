

/*
    Blockquote
*/
.blockquote {
    padding-top:7.5%;
    padding-bottom: 7.5%;
    .blockquote__quote {
        color: $twilight-blue;
        font-family: "bressay-display", serif;
        font-size: 40px;
        font-size: 4rem;
        line-height: 1.35;
        text-align: center;
        width: 66%;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            font-size: 22px;
            font-size: 2.2rem;
            line-height: 1.18;
            letter-spacing: normal;
        }
    }

    .blockquote__byline {
        font-weight: bold;
        letter-spacing: 2px;
        margin-top: 40px;
        text-align: center;
        font-size: 12px;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $twilight-blue;

        &:before {
            content: '- ';
        }

        @include media-breakpoint-down(md) {
            font-size: 10px;
            font-size: 1.0rem;
            text-transform: uppercase;
        }
    }

    .blockquote__link {
        margin-top: 40px;
        text-align: center;
        color: $twilight-blue;

        @include media-breakpoint-down(lg) {
            margin-top: 24px;
        }
    }
}