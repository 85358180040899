.press-release {

	h1 {
		margin-bottom: 107px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 48px;
		}
	}

	.press-container {
		padding: 0px;
		margin-left: -15px;
		max-width:480px;
		min-height: 185px;
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid $twilight-blue;
		&.last {
			border-bottom: none;
			margin-bottom: 0px;
		}
	}
	.press-heading {
			font-family: Europa, sans-serif;
		  font-size: 24px;
		  font-size: 2.4rem;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.33;
		  color: $twilight-blue;


		  @media only screen and (max-width: 1555px) {
		  	font-size: 20px;
		  	font-size: 2.0rem;
		  }

		  &:hover, :focus {
		  	color: $twilight-blue;
		  }
		}

		.date {
			font-family: Europa, sans-serif;
		  font-size: 16px;
		  font-size: 1.6rem;
		  font-weight: bold;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: normal;
		  letter-spacing: 2px;
		  color: $charcoal-grey;
		  text-transform: uppercase;

		  @media only screen and (max-width: 1555px) {
		  	font-size: 13px;
		  	font-size: 1.3rem;
		  }

		  @include media-breakpoint-down(sm) {
		  	margin-bottom: 24px;
		  }
		}

	}
