

/*
    Single page
*/
.single-page {
    margin-top: 240px;
    margin-bottom: 240px;

    @include media-breakpoint-down(md) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.heading1 {
    margin-bottom: 30px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.heading2-single {
    font-family: Europa, sans-serif;
    font-size: 28px;
    font-size: 2.8rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $twilight-blue;
    margin-top: 120px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.heading2-single--bold {
    font-weight: bold;
    text-transform: uppercase;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.heading3-single {
    font-family: Europa, sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    color: $charcoal-grey;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

p.sub-text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.93;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.terms-numbered-header {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

ul.terms li, ol.terms li {
    font-family: Europa, sans-serif;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5;
}

// ==========================================================================
// Styling for Single News and Single Story page
// ==========================================================================
.single-story {

    .intro__fullbleed {
        background-position-y: 15%;
        @include media-breakpoint-down(md) {
           
        }

        .row.bottom.left {
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
            
        }

        .intro__callout-text.bottom-left {
            flex-direction: unset !important;          
        }//.intro__callout-text
    
    }//.intro__fullbleed

}