

/*
    Partners page
*/
.partners {
    margin-top: 159px;

     @include media-breakpoint-down(md) {
            margin-top: 43px;
    }

    .partners__intro {
        margin-bottom: 75px;

          @include media-breakpoint-down(md) {
            margin-bottom: 57px;
          }

        h1 {
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          font-size: 12px;
          font-size: 1.2rem;
          line-height: 2.25;
          letter-spacing: 1.9px;
        }

        p {
            margin-bottom: 30px;
        }
    }

    .partner {



        .partner__logo {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
            max-height: 100px;
        }

        .partner__info {
            margin-bottom: 80px;
            margin-top: 20px;
            max-width: 378px;

            @include media-breakpoint-down(md) {
                margin-bottom: 57px;
                max-width: unset;
            }
        }

        .partner__name {
            color: $twilight-blue;
            font-weight: bold;
        }
    }

    .partners__tier1 {
        .partner__info p {
            font-weight: normal;
            letter-spacing: normal;
            text-transform: none;
        }
    }

    .partners__tier2, .partners__tier3 {
        padding: 70px 0 20px 0;
        background-color: $light-grey;

        h2 {
            margin-bottom: 50px;


            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }
        }

        .partner {
            width: 25%;

            // @include media-breakpoint-up(lg) {
            //     &:not(:last-of-type){
            //         border-right: solid 1px $twilight-blue;
            //     }
            // }
           .partner-info {
            margin-bottom: 0px;
            margin-top: 0px;
           }
            .partner__info p {
                color: $twilight-blue;
                letter-spacing: normal;
                text-align: center;
                text-transform: none;
            }
        }
    }

    .partners__tier3 {
        .partner {
            .partner__info {
                margin-bottom: 20px;
            }
        }
    }

}