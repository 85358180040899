

/*
    Intro pattern
*/
.intro {
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0px 0px 60px 0px;
    background: linear-gradient(to right, $light-grey 30%, $white 0);

    &.order-12 {
            background: linear-gradient(to left, $light-grey 30%, $white 0);
    }

    .intro__link {
        margin-top: 40px;
    }

    @include media-breakpoint-down(md) {
        background: white;

        .intro__image {
            margin-top: 60px;
        }
    }
}