//Styles for specifically targetting IE10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	//Fix: display off right side of window
	.news {
		.justify-content-center {
			justify-content: flex-start !important;
			padding-left: 100px;
		}
	}
	.image-callout {
		position: relative;
	}
	.image-callout .animate-slide-left {
		right: auto;
	}

	.image-callout .animate-slide-right {
		right: 0;
		top: 115px;
	}
}